import { useGetClient } from "../utils/HttpCommon";
import { Media } from "../views/Media/MediaInterfaces";

export interface MediaListRequestParameters {
  bible: string | undefined;
}

export interface MediaResponse {
  data: Media[];
}

const useMediaService = () => {
  const { http } = useGetClient();

  const get = async (params: MediaListRequestParameters): Promise<Media[]> => {
    const response = await http.get<MediaResponse>(
      `/bible/${params.bible}/media`,
    );
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const updateMedia = async (media: Media): Promise<Media[]> => {
    const response = await http.put<Media[]>(
      `/media/${media.id}${media.sendToMonday ? "?sendToMonday=true" : ""}`,
      {
        stocknumber: media.stocknumber,
        country: media.country,
        ietfTag: media.ietfTag,
        archived: media.archived,
        licenseGroupId: media.licenseGroupId,
        bibleId: media.bibleId,
      },
    );
    const { data } = response;
    return data;
  };

  const createMedia = async (media: Media): Promise<Media[]> => {
    const response = await http.post<Media[]>(`/bible/${media.bibleId}/media`, {
      id: media.id,
      stocknumber: media.stocknumber,
      country: media.country,
      ietfTag: media.ietfTag,
      archived: media.archived,
    });
    const { data } = response;
    return data;
  };

  const deleteMedia = async (media: Media): Promise<Media[]> => {
    const response = await http.delete<Media[]>(`/media/${media.id}`);
    const { data } = response;
    return data;
  };

  return {
    get,
    updateMedia,
    createMedia,
    deleteMedia,
  };
};

export default useMediaService;
