import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
      <Typography variant="h4" color="error">
        Authentication Failed
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Sorry, something went wrong during the login process.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        sx={{ marginTop: "20px" }}
      >
        Go back to Home
      </Button>
    </Box>
  );
};

export default CustomErrorPage;
