import { Container, Paper } from "@mui/material";
import PermissionTable from "./PermissionTable";
import {
  PermissionTableProps,
  PermissionTableContainerProps,
} from "./PermissionInterfaces";

const PermissionTableContainer = ({
  isCustomizeEnable,
  hasCustomLicensePermission,
  creatingPattern,
  editingPattern,
  permissionOptionsData,
  permissionPatternDetail,
  newPattern,
  selectedMedia,
  customLicensePermission,
  permissionData,
  updateCheckbox,
  updateCustomCheckbox,
  editingSelectedPattern,
}: PermissionTableContainerProps) => {
  const getPermissionTableProps = (): PermissionTableProps | null => {
    if (
      isCustomizeEnable &&
      selectedMedia.licenseGroupId &&
      selectedMedia.mediaModeType
    ) {
      return {
        allPermissionOptions: permissionOptionsData,
        permissionOptionsSelected: customLicensePermission?.accessGroups ?? [],
        disabled: false,
        handleCheckboxChange: updateCustomCheckbox,
        mediaTypeMode: selectedMedia.mediaModeType,
        belongPermissionPattern: false,
      };
    }
    if (hasCustomLicensePermission && selectedMedia.mediaModeType) {
      const isEditing = creatingPattern || editingPattern;
      const optionsSelected = isEditing
        ? newPattern?.permissionOptions
        : editingSelectedPattern
          ? permissionPatternDetail?.permissionOptions
          : permissionData?.accessGroups;

      return {
        allPermissionOptions: permissionOptionsData,
        permissionOptionsSelected: optionsSelected ?? [],
        disabled: !isEditing,
        handleCheckboxChange: updateCustomCheckbox,
        mediaTypeMode: selectedMedia.mediaModeType,
        belongPermissionPattern: false,
      };
    }
    if (!isCustomizeEnable && permissionPatternDetail?.permissionOptions) {
      const isEditing = creatingPattern || editingPattern;

      const optionsSelected = isEditing
        ? newPattern?.permissionOptions
        : permissionPatternDetail?.permissionOptions;

      return {
        allPermissionOptions: permissionOptionsData,
        permissionOptionsSelected: optionsSelected ?? [],
        disabled: !isEditing,
        handleCheckboxChange: updateCheckbox,
        belongPermissionPattern: true,
      };
    }
    if (creatingPattern) {
      const optionsSelected = newPattern?.permissionOptions ?? [];

      return {
        allPermissionOptions: permissionOptionsData,
        permissionOptionsSelected: optionsSelected,
        disabled: false,
        handleCheckboxChange: updateCheckbox,
        belongPermissionPattern: true,
      };
    }
    return null;
  };

  const tableProps = getPermissionTableProps();

  return (
    <Container component={Paper} maxWidth="xl" sx={{ padding: "15px" }}>
      {tableProps && <PermissionTable {...tableProps} />}
    </Container>
  );
};

export default PermissionTableContainer;
