import { Theme } from "@mui/material/styles";
import { pxToRem, theme } from "../../utils/CustomTheme";

export const styles = {
  container: (baseTheme: Theme) => ({
    backgroundColor: baseTheme.palette.background.default,
  }),
  homeTitle: {
    fontWeight: 300,
    fontSize: pxToRem(42),
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
  },
  pageMargin: {
    marginBottom: "100px",
  },
};
