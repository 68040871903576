import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { StockNumber } from "./StockNumberInterfaces";
import { styles } from "./styles";
import { useUserInfo } from "../../provider/UserProvider";

export interface StockNumberRowProps {
  initialData: StockNumber;
  handleInputChange: (
    stockNumber: StockNumber,
    action: string,
  ) => (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export const StockNumberRow = (props: StockNumberRowProps) => {
  const [stockNumber, setStockNumber] = useState<StockNumber>(
    props.initialData,
  );

  const { canAdminMedia } = useUserInfo();

  useEffect(() => {
    setStockNumber(props.initialData);
  }, [props.initialData]);

  const sentToMondayCheckboxDisabled =
    stockNumber.archived && !stockNumber.isInMonday;

  return (
    <TableRow>
      <TableCell sx={[stockNumber.isInMonday ? styles.greenBackground : null]}>
        {stockNumber.id}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={props.handleInputChange(stockNumber, "sendToMonday")}
          disabled={!canAdminMedia || sentToMondayCheckboxDisabled}
        >
          {stockNumber.isInMonday ? (
            <CheckBox color="secondary" fontSize="small" />
          ) : (
            <CheckBoxOutlineBlank color="secondary" fontSize="small" />
          )}
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <RadioGroup
          row
          value={stockNumber.archived}
          onChange={props.handleInputChange(stockNumber, "archive")}
          name="archived"
          style={styles.centerCellContent}
        >
          <FormControlLabel
            value={true}
            control={<Radio size="small" disabled={!canAdminMedia} />}
            label="Y"
          />
          <FormControlLabel
            value={false}
            control={<Radio size="small" disabled={!canAdminMedia} />}
            label="N"
          />
        </RadioGroup>
      </TableCell>
    </TableRow>
  );
};
