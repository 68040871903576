import React from "react";
import { useEffect, useRef, useState, useMemo } from "react";
import { styles } from "./styles";
import {
  Grid2,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Stack,
  Tooltip,
  TextField,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { Check, Clear, Delete } from "@mui/icons-material";
import { AxiosError } from "axios";
import { Media as MediaInterface, MediaProps } from "./MediaInterfaces";
import Permission from "../Permission/Permission";
import { AlertMessage } from "../Home/HomeInterfaces";
import { useUserInfo } from "../../provider/UserProvider";
import { Permission as PermissionInterface } from "../Permission/PermissionInterfaces";
import useMediaService from "../../services/MediaService";
import { useGetMediaList } from "../../hooks/useGetMediaList";
import { useGetPermission } from "../../hooks/useGetPermission";
import { useGetLicenseGroupList } from "../../hooks/useGetLicenseGroupList";
import { AutogenerateOptionsDialogProps } from "../Bibles/BiblesInterfaces";
import AutogenerateOptionsDialog from "../Bibles/AutogenerateOptionsDialog";
import useLanguageService from "../../services/LanguageService";

const Media = ({
  selectedBible,
  setAlertList,
  showSensitiveFields,
  scrollToMedia,
  showArchived,
  selectedLanguageId,
  enableAutoScrolling,
}: MediaProps) => {
  const { deleteMedia, createMedia, updateMedia } = useMediaService();
  const { generateMetadata } = useLanguageService();
  const [mediaList, setMediaList] = useState<MediaInterface[]>([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<MediaInterface>();
  const [isSavingMedia, setIsSavingMedia] = useState<boolean>(false);
  const [isGeneratingMedia, setIsGeneratingMedia] = useState<boolean>(false);
  const [editingMedia, setEditingMedia] = useState<number>();
  const [permission, setPermission] = useState<PermissionInterface>();
  const [openMediaDialog, setOpenMediaDialog] = useState<boolean>(false);
  const { canAdminMedia } = useUserInfo();
  const permissionRef = useRef<React.RefAttributes<HTMLDivElement>>(null);
  const permissionSectionRef = useRef<HTMLDivElement>(null);

  const {
    isFetching: isMediaFetching,
    isError: isMediaError,
    data: mediaData,
    refetch: getMediaList,
  } = useGetMediaList({ selectedBible });

  useEffect(() => {
    if (isMediaError) {
      setAlertList((prev: AlertMessage[]) => {
        return [
          ...prev,
          {
            message: "There was an error fetching Media List!",
            key: new Date().getTime(),
          },
        ];
      });
    }
  }, [isMediaError, setAlertList]);

  const { data: licenseGroupsData, refetch: getLicenseGroupList } =
    useGetLicenseGroupList({ selectedBible });

  useEffect(() => {
    if (selectedBible) {
      setMediaList([]);
      setSelectedMedia(undefined);
      getMediaList();
    } else {
      setMediaList([]);
      setSelectedMedia(undefined);
    }
  }, [selectedBible, getMediaList]);

  useEffect(() => {
    if (mediaData) {
      setMediaList(mediaData);

      if (mediaData.length === 1) {
        setSelectedMedia(mediaData[0]);
      }

      if (mediaData.length > 0) {
        scrollToMedia();
      }

      getLicenseGroupList();
    } else {
      setMediaList([]);
    }
  }, [mediaData, scrollToMedia, getLicenseGroupList]);

  const handleSelectMedia = (mediaRow: MediaInterface) => {
    setSelectedMedia(mediaRow);
    if (editingMedia === undefined && enableAutoScrolling) {
      permissionSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleConfirmDelete = () => {
    setConfirmationDialogOpen(false);
    if (selectedMedia?.id) {
      deleteMedia(selectedMedia).then(() => {
        setSelectedMedia(undefined);
        getMediaList(undefined);
      });
    }
  };

  const handleCancelDelete = () => {
    setConfirmationDialogOpen(false);
  };

  const mediaRecordsMatch = (
    media1: MediaInterface | undefined,
    media2: MediaInterface,
  ): boolean => {
    if (media1 && media2) {
      return (
        media1.id === media2.id &&
        media1.mediaType === media2.mediaType &&
        media1.stocknumber === media2.stocknumber
      );
    }

    return false;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    changedIndex: number,
    mediaRow: MediaInterface,
  ) => {
    event.stopPropagation();
    setEditingMedia(changedIndex);

    setMediaList((prevState) => {
      const mediaListCopy = [...prevState];
      if (mediaListCopy) {
        mediaListCopy.forEach((media, index) => {
          if (changedIndex === index) {
            if (event.target.name === "archived") {
              media.archived = event.target.value === "true";
            } else {
              media[event.target.name] = event.target.value;
            }
          }
        });
      }
      return mediaListCopy;
    });

    setSelectedMedia(mediaRow);
  };

  const handleAutoselectChange = (
    value: number | null,
    changedIndex: number,
    mediaRow: MediaInterface,
  ) => {
    setEditingMedia(changedIndex);

    setMediaList((prevState) => {
      const mediaListCopy = [...prevState];
      if (mediaListCopy) {
        mediaListCopy.forEach((media, index) => {
          if (changedIndex === index && value) {
            media.licenseGroupId = value;
          }
        });
      }
      return mediaListCopy;
    });
    setSelectedMedia(mediaRow);
  };

  const saveEdits = (index: number) => {
    setIsSavingMedia(true);

    if (mediaList) {
      if (
        mediaList[index]?.sendToMonday &&
        (!permission ||
          permission?.copyright === "" ||
          permission?.licensors.length === 0)
      ) {
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message:
                "This stocknumber does not have a licensor associated with it. Please associate a licensor and add the copyright, then try again",
              key: new Date().getTime(),
              type: "error",
            },
          ];
        });
        setIsSavingMedia(false);
        return;
      }

      if (mediaList[index] && !mediaList[index].id) {
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message: "Media ID is required.",
              key: new Date().getTime(),
            },
          ];
        });
        setIsSavingMedia(false);
        return;
      }

      let serviceCall;
      if (mediaList[index].isNew) {
        serviceCall = createMedia(mediaList[index]);
      } else {
        serviceCall = updateMedia({
          ...mediaList[index],
          bibleId: mediaList[index]?.bibleIds[0],
        });
      }

      serviceCall
        .then(() => {
          setAlertList((prev: AlertMessage[]) => {
            return [
              ...prev,
              {
                message: "Media saved.",
                key: new Date().getTime(),
                type: "success",
              },
            ];
          });

          setEditingMedia(undefined);
          getMediaList();
          setIsSavingMedia(false);
        })
        .catch(() => {
          setAlertList((prev: AlertMessage[]) => {
            return [
              ...prev,
              {
                message: "There was an error saving!",
                key: new Date().getTime(),
              },
            ];
          });
          setIsSavingMedia(false);
        });
    }
  };

  const cancelEdits = () => {
    getMediaList().then(() => {
      if (mediaData) {
        setMediaList(mediaData);
      }
    });
    setEditingMedia(undefined);
  };

  const deleteRow = () => {
    setConfirmationDialogOpen(true);
  };

  const {
    isFetching: isPermissionFetching,
    data: permissionData,
    refetch: getPermission,
  } = useGetPermission(selectedMedia ?? ({} as MediaInterface));

  useEffect(() => {
    setPermission(permissionData);
  }, [permissionData, setPermission]);

  useEffect(() => {
    if (selectedMedia?.licenseGroupId) {
      getPermission();
    }
  }, [selectedMedia, getPermission]);

  const dialogProps: AutogenerateOptionsDialogProps = {
    open: openMediaDialog,
    disabled: isGeneratingMedia,
    mediaRecords: mediaData ?? [],
    async onClose(shouldAutogenerate, selectedOptions) {
      if (shouldAutogenerate && selectedBible) {
        try {
          setIsGeneratingMedia(true);
          const response = await generateMetadata(
            selectedBible,
            selectedOptions,
          );
          setIsGeneratingMedia(false);

          if (response === "success") {
            await getMediaList();
            setAlertList((prev: AlertMessage[]) => {
              return [
                ...prev,
                {
                  message: "The media has been generated successfully!",
                  key: new Date().getTime(),
                  type: "success",
                },
              ];
            });
          }
        } catch (error: unknown) {
          setIsGeneratingMedia(false);
          setAlertList((prev: AlertMessage[]) => {
            let errorMessage = "An error occurred";

            if (error instanceof AxiosError && error.response) {
              errorMessage = error.response.data?.error || errorMessage;
            } else if (error instanceof Error) {
              errorMessage = error.message;
            }

            return [
              ...prev,
              {
                message: errorMessage,
                key: new Date().getTime(),
                type: "error",
              },
            ];
          });
        }
      } else if (!selectedBible) {
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message: "There is not a Bible selected.",
              key: new Date().getTime(),
              type: "warning",
            },
          ];
        });
      }
      setOpenMediaDialog(false);
    },
    bibleId: selectedBible ?? "",
  };

  const generateMedia = (): void => {
    setOpenMediaDialog(true);
  };

  const memoizedPermissionData = useMemo(
    () => permissionData,
    [permissionData],
  );
  const memoizedSelectedMedia = useMemo(
    () => selectedMedia ?? ({} as MediaInterface),
    [selectedMedia],
  );

  return (
    <>
      <AutogenerateOptionsDialog {...dialogProps} />
      <Backdrop
        sx={styles.backdrop}
        open={
          isMediaFetching ||
          isSavingMedia ||
          isPermissionFetching ||
          isGeneratingMedia
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={confirmationDialogOpen}>
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this media?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
          <Button onClick={handleCancelDelete} color="secondary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Grid2 container justifyContent="space-between" alignItems="baseline">
        <Grid2 size={9}>
          <Typography variant="h6" sx={styles.subSectionTitle}>
            Media
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  ID
                  <Tooltip title="ID Required">
                    <Typography
                      color="primary"
                      variant="body1"
                      sx={{ display: "inline" }}
                    >
                      *&nbsp;
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "160px" }}>Mode_Type</TableCell>
                <TableCell>Stock Number</TableCell>
                <TableCell>License Group</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mediaList?.map(
                (media: MediaInterface, index: number) =>
                  (showArchived ||
                    !media.archived ||
                    editingMedia === index) && (
                    <Tooltip
                      key={`tooltip_${index}`}
                      title={
                        media?.contentLoaded
                          ? `Media: ${media?.id} has been loaded in Biblebrain`
                          : null
                      }
                    >
                      <TableRow
                        key={index}
                        sx={[
                          mediaRecordsMatch(selectedMedia, media) ||
                          mediaList?.length === 1
                            ? styles.selected
                            : null,
                          styles.pointer,
                        ]}
                      >
                        <TableCell
                          sx={[
                            styles.rightBorder,
                            media?.contentLoaded
                              ? styles.greyedBackground
                              : null,
                          ]}
                          onClick={() => handleSelectMedia(media)}
                        >
                          {media.isNew ? (
                            <TextField
                              value={media.id || ""}
                              onChange={(event) =>
                                handleInputChange(event, index, media)
                              }
                              name="id"
                              size="small"
                              disabled={!canAdminMedia}
                              required
                            />
                          ) : (
                            media.id
                          )}
                        </TableCell>
                        <TableCell
                          sx={[
                            styles.rightBorder,
                            media?.contentLoaded
                              ? styles.greyedBackground
                              : null,
                          ]}
                          onClick={() => handleSelectMedia(media)}
                        >
                          {media.isNew ? (
                            <TextField
                              value={media.mediaType || ""}
                              onChange={(event) =>
                                handleInputChange(event, index, media)
                              }
                              name="mediaType"
                              size="small"
                              disabled={!canAdminMedia}
                            />
                          ) : (
                            media.mediaType
                          )}
                        </TableCell>
                        <TableCell
                          sx={[
                            styles.rightBorder,
                            media?.contentLoaded
                              ? styles.greyedBackground
                              : null,
                          ]}
                          onClick={() => handleSelectMedia(media)}
                        >
                          {media.isNew ? (
                            <TextField
                              value={media.stocknumber || ""}
                              onChange={(event) =>
                                handleInputChange(event, index, media)
                              }
                              name="stocknumber"
                              size="small"
                              fullWidth
                              disabled={!canAdminMedia}
                            />
                          ) : (
                            media.stocknumber
                          )}
                        </TableCell>
                        <TableCell
                          sx={[
                            styles.rightBorder,
                            media?.contentLoaded
                              ? styles.greyedBackground
                              : null,
                          ]}
                          onClick={() => handleSelectMedia(media)}
                        >
                          {media.isNew ? (
                            <Stack direction="row" spacing={0}>
                              <Autocomplete
                                autoHighlight
                                onChange={(event, value) =>
                                  handleAutoselectChange(value, index, media)
                                }
                                value={
                                  media.licenseGroupId > 0
                                    ? media.licenseGroupId
                                    : null
                                }
                                getOptionLabel={(option) =>
                                  licenseGroupsData?.find(
                                    (licenseGroup) =>
                                      licenseGroup.id === option,
                                  )?.name ?? ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                options={
                                  licenseGroupsData?.map((group) => group.id) ??
                                  []
                                }
                                sx={{ minWidth: "140px" }}
                                size="small"
                                fullWidth
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={!canAdminMedia}
                              />
                            </Stack>
                          ) : (
                            licenseGroupsData?.reduce((result, current) => {
                              if (current.id === media.licenseGroupId) {
                                result = current.name;
                              }
                              return result;
                            }, "")
                          )}
                        </TableCell>
                        <TableCell
                          sx={[styles.alignCenter, styles.controlCellWidth]}
                          onClick={() => handleSelectMedia(media)}
                        >
                          {canAdminMedia && editingMedia === index && (
                            <Stack direction="row" spacing={0}>
                              <Tooltip title="Save Changes">
                                <IconButton onClick={() => saveEdits(index)}>
                                  <Check color="success" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Cancel Changes">
                                <IconButton onClick={cancelEdits}>
                                  <Clear color="primary" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}
                          {canAdminMedia &&
                            editingMedia !== index &&
                            !media.contentLoaded && (
                              <Tooltip title="Delete Row">
                                <IconButton onClick={deleteRow}>
                                  <Delete color="primary" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  ),
              )}
              <TableRow>
                <TableCell>
                  <Button
                    disabled={
                      !mediaList || mediaList.length === 0 || !canAdminMedia
                    }
                    color="primary"
                    variant="text"
                    onClick={generateMedia}
                  >
                    Generate
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
      <Grid2 container spacing={2} direction={"column"}>
        <Grid2 size={12}>
          <div ref={permissionSectionRef}></div>
          <Permission
            selectedBibleId={selectedBible}
            selectedLanguageId={selectedLanguageId}
            permissionData={memoizedPermissionData}
            getPermission={getPermission}
            selectedMedia={memoizedSelectedMedia}
            ref={permissionRef}
            setAlertList={setAlertList}
            showSensitiveFields={showSensitiveFields}
            licenseGroups={licenseGroupsData ?? []}
          />
        </Grid2>
      </Grid2>
    </>
  );
};

export default React.memo(Media);
