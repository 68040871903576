import { useMemo } from "react";
import { useUserInfo } from "../provider/UserProvider";

const ADMIN_USER = "adminUser";
const GUEST_USER = "guestUser";
const RESTRICTED_READ_ONLY_USER = "restrictedReadOnlyUser";

export const useUserAuthorization = () => {
  const { canAdminBibles, canAdminPermissions, canRestrictedReadOnly } =
    useUserInfo();

  const userType = useMemo((): string => {
    if (canAdminBibles && canAdminPermissions) {
      return ADMIN_USER;
    }

    if (canRestrictedReadOnly) {
      return RESTRICTED_READ_ONLY_USER;
    }

    return GUEST_USER;
  }, [canAdminBibles, canAdminPermissions, canRestrictedReadOnly]);

  return {
    userType,
  };
};
