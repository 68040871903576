import { useQuery } from "@tanstack/react-query";
import useLanguageService from "../services/LanguageService";
import { LanguageDetails } from "../views/Language/LanguageInterfaces";
import { SearchResult } from "../views/Search/SearchInterfaces";

interface UseGetLanguageDetailsProps {
  selectedLanguage: SearchResult | undefined;
}

const options = {
  enabled: false,
  retry: false,
  retryOnMount: false,
};

export const useGetLanguageDetails = ({
  selectedLanguage,
}: UseGetLanguageDetailsProps) => {
  const { get } = useLanguageService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    LanguageDetails,
    Error
  >(
    {
      queryKey: ["query-get-language-details", selectedLanguage],
      queryFn: () => get({ selectedLanguage }),
      ...options
    }
  );

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
