import { useQuery } from "@tanstack/react-query";
import usePermissionService from "../services/PermissionService";
import { Permission } from "../views/Permission/PermissionInterfaces";
import { Media } from "../views/Media/MediaInterfaces";

const options = {
  enabled: false,
  retry: false,
  retryOnMount: false,
  staleTime: 5 * 60 * 1000,
};

export const useGetPermission = (media: Media) => {
  const { getAgreement } = usePermissionService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    Permission,
    Error
  >({
    queryKey: ["query-get-permission", media?.licenseGroupId],
    queryFn: () => getAgreement(media?.licenseGroupId),
    ...options,
  });

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
