import { useQuery } from "@tanstack/react-query";
import useMediaService from "../services/MediaService";
import { Media } from "../views/Media/MediaInterfaces";

interface UseGetMediaListProps {
  selectedBible: string | undefined;
}

const options = {
  enabled: false,
  retry: false,
  retryOnMount: false,
};

export const useGetMediaList = ({
  selectedBible: bible,
}: UseGetMediaListProps) => {
  const { get } = useMediaService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    Media[],
    Error
  >({
    queryKey: ["query-get-media-list", bible],
    queryFn: () => get({ bible }),
    ...options
  });

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
