export const StockNumberIds = [
  "N1",
  "N2",
  "O1",
  "O2",
  "P1",
  "P2",
  "S1",
  "S2",
  "Y1",
  "Y2",
];
