import { useQuery } from "@tanstack/react-query";
import usePermissionService from "../services/PermissionService";
import { Licensor } from "../views/Permission/PermissionInterfaces";

const options = {
  enabled: false,
  retry: false,
  retryOnMount: false,
};

export const useGetLicensorList = () => {
  const { getLicensorList } = usePermissionService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    Licensor[],
    Error
  >({
    queryKey: ["query-get-licensor-list"],
    queryFn: () => getLicensorList(),
    ...options
  });

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
