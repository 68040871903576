import {
  Permission as PermissionInterface,
  PermissionOption,
} from "./PermissionInterfaces";
import { MODE_ID_AUDIO, MODE_ID_TEXT, MODE_ID_VIDEO } from "./config";

// Utility function to deeply copy permission
export const deepPermissionCopy = (obj: PermissionInterface) => {
  return JSON.parse(JSON.stringify(obj));
};

// Check if two permission arrays are equal by ID
export const arePermissionsEqualById = (
  permissionsSource: PermissionOption[],
  permissionsTarget: PermissionOption[],
): boolean => {
  const everyIdInArr1ExistsInArr2 = permissionsSource.every((permission1) =>
    permissionsTarget.some((permission2) => permission2.id === permission1.id),
  );
  const everyIdInArr2ExistsInArr1 = permissionsTarget.every((permission2) =>
    permissionsSource.some((permission1) => permission1.id === permission2.id),
  );

  return everyIdInArr1ExistsInArr2 && everyIdInArr2ExistsInArr1;
};

// Extract copyright information based on mode
export const extractCopyrightInfo = (
  text: string,
  mode: number,
): string | null => {
  if (mode === MODE_ID_AUDIO) {
    return extractAudioInfo(text);
  }

  if (mode === MODE_ID_VIDEO) {
    return extractVideoInfo(text);
  }

  if (mode === MODE_ID_TEXT) {
    return extractTextInfo(text);
  }

  return text;
};

// Extract specific copyright information
const extractAudioInfo = (text: string): string | null => {
  const regex = /Audio:\s*(.*?)(\r?\n|$)/;
  const match = regex.exec(text);
  return match?.[1] ?? null;
};

const extractVideoInfo = (text: string): string | null => {
  const regex = /Video:\s*(.*?)(\r?\n|$)/;
  const match = regex.exec(text);
  return match?.[1] ?? null;
};

const extractTextInfo = (text: string): string | null => {
  const regex = /Text:\s*(.*?)(\r?\n|$)/;
  const match = regex.exec(text);
  return match?.[1] ?? null;
};

export const addCopyrightCombined = (
  mediaModeType: number,
  oldCopyrightText: string,
  copyrightText: string,
): string => {
  const sections = [
    {
      type: "Text",
      regex: /Text:\s*(.*?)(\r?\n|$)/,
      condition: mediaModeType === MODE_ID_TEXT,
    },
    {
      type: "Audio",
      regex: /Audio:\s*(.*?)(\r?\n|$)/,
      condition: mediaModeType === MODE_ID_AUDIO,
    },
    {
      type: "Video",
      regex: /Video:\s*(.*?)(\r?\n|$)/,
      condition: mediaModeType === MODE_ID_VIDEO,
    },
  ];

  const section = sections.find((s) => s.condition);

  if (section) {
    let copyrightCombined = oldCopyrightText;
    const { type, regex } = section;
    if (oldCopyrightText.includes(`${type}:`)) {
      // Replace the existing section
      copyrightCombined = copyrightCombined.replace(
        regex,
        `${type}: ${copyrightText}$2`,
      );
    } else {
      // Add the section if it doesn't exist
      copyrightCombined += `\n${type}: ${copyrightText}`;
    }
    return copyrightCombined;
  }

  return copyrightText;
};

export const removeFirstAndConcatenate = (str: string) => {
  // e.g "BiblbeID-text"
  const parts = str.split("-");

  if (parts.length > 1) {
    parts.shift();
    // Concatenate the remaining elements back into a string e.g. audio
    return parts.join("-");
  }

  // If there's only one element, return the original string
  return str;
};

export const modeTypeNameFromLicenseName = (str: string) => {
  // e.g "BiblbeID-audio-non-drama-NT"
  const parts = str.split("-");

  if (parts.length > 1) {
    return parts[1];
  }

  // If there's only one element, return the original string
  return str;
};

export const getTextCopyrightKey = (
  copyrightModeKey: string,
  permissionModeTypeName: string,
) => {
  // In some cases, we have a license group like BibleId-audio-non-drama-NT
  // and we need to find the related text key, e.g., BibleId-text-NT.
  return copyrightModeKey
    .replace(permissionModeTypeName, "text")
    .replace("non-drama-", "")
    .replace("drama-", "");
};
