import axios from "axios";
import { useMemo } from "react";
import { useUserAuthorization } from "../hooks/useUserAuthorization";

export const useGetClient = (
  baseURL: string = process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}/api`
    : `/api`,
) => {
  const { userType } = useUserAuthorization();

  const http = useMemo(() => {
    return axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: userType,
      },
    });
  }, [baseURL, userType]);

  return { http };
};
