import { useGetClient } from "../utils/HttpCommon";

import { AutogeneratePostOption } from "../views/Bibles/BiblesInterfaces";
import {
  Country,
  FcbhLanguage,
  LanguageDetails,
} from "../views/Language/LanguageInterfaces";
import { SearchResult } from "../views/Search/SearchInterfaces";

export interface LanguageDetailsRequestParameters {
  selectedLanguage: SearchResult | undefined;
}

export interface LanguageDetailsResponse {
  data: LanguageDetails;
}

export interface AddLanguageResponse {
  data: FcbhLanguage;
}

export interface BibleRequestParameters {
  query: number | undefined;
}

export interface BibleSaveValues {
  id: string;
  status: number;
}

export interface GenerateMediaResponse {
  data: string;
}

export interface CountryResponse {
  data: Country[];
}

const useLanguageService = () => {
  const { http } = useGetClient();

  const getCountryList = async (): Promise<Country[]> => {
    const response = await http.get<CountryResponse>(`/countries`);
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const get = async (
    params: LanguageDetailsRequestParameters,
  ): Promise<LanguageDetails> => {
    const response = await http.get<LanguageDetailsResponse>(
      `/language?id=${params.selectedLanguage?.languageId}`,
    );
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const updateLanguage = async (
    language: FcbhLanguage,
  ): Promise<LanguageDetails> => {
    const { id, ...updateLanguageBody } = language; //remove ID field from body
    const response = await http.put<LanguageDetailsResponse>(
      `/language/${id}`,
      updateLanguageBody,
    );
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const addLanguage = async (language: FcbhLanguage): Promise<FcbhLanguage> => {
    const response = await http.post<AddLanguageResponse>(
      `/language`,
      language,
    );
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const generateMetadata = async (
    bibleId: string,
    selectedOptions: AutogeneratePostOption,
  ): Promise<string> => {
    const response = await http.post<GenerateMediaResponse>(
      `/bible/${bibleId}/metadata`,
      { ...selectedOptions },
    );
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  return {
    getCountryList,
    get,
    updateLanguage,
    addLanguage,
    generateMetadata,
  };
};

export default useLanguageService;
