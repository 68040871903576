import { useQuery } from "@tanstack/react-query";
import usePermissionService, {
  PermissionPatternDetail,
} from "../services/PermissionService";

export const useGetPermissionsPattern = (patternId: number) => {
  const options = {
    enabled: !!patternId,
    retry: false,
    retryOnMount: false,
    staleTime: 5 * 60 * 1000,
  };

  const { getPermissionPattern } = usePermissionService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    PermissionPatternDetail,
    Error
  >({
    queryKey: ["query-get-permissions-pattern-", patternId],
    queryFn: () => getPermissionPattern(patternId),
    ...options,
  });

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
