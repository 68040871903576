import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  InputLabel,
} from "@mui/material";
import { PermissionTableProps, PermissionOption } from "./PermissionInterfaces";
import { MODE_ID_AUDIO, MODE_ID_VIDEO, MODE_ID_TEXT } from "./config";

interface ProcessedData {
  name: string;
  description?: string;
  modes: Record<number, number>;
}

type GroupedData = Record<string, ProcessedData>;

const processData = (data: PermissionOption[]): ProcessedData[] => {
  const grouped: GroupedData = data.reduce((acc: GroupedData, item) => {
    if (!acc[item.name]) {
      acc[item.name] = {
        name: item.name,
        description: item.description,
        modes: {},
      };
    }
    if (item.modeId !== undefined) {
      acc[item.name].modes[item.modeId] = item.id;
    }
    return acc;
  }, {});

  return Object.values(grouped);
};

const mapPermissionOption = (
  permissionId: number,
  modeId: number,
  checkedPermissionList: PermissionOption[] = [],
) => {
  return !!checkedPermissionList.find(
    (permission) =>
      permission.id === permissionId && permission.modeId === modeId,
  );
};

const PermissionTable = ({
  allPermissionOptions,
  permissionOptionsSelected,
  disabled,
  handleCheckboxChange,
  mediaTypeMode,
  belongPermissionPattern,
}: PermissionTableProps) => {
  const processedData = processData(allPermissionOptions);

  const isCheckboxDisable = (modeToValidate: number): boolean => {
    if (belongPermissionPattern) {
      return disabled;
    }
    return disabled || mediaTypeMode !== modeToValidate;
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center">
            <InputLabel variant="standard">Audio</InputLabel>
          </TableCell>
          <TableCell align="center">
            <InputLabel variant="standard">Text</InputLabel>
          </TableCell>
          <TableCell align="center">
            <InputLabel variant="standard">Video</InputLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {processedData.map((item) => (
          <TableRow key={item.name}>
            <TableCell component="th" scope="row">
              <Tooltip
                key={`permission_options_data_${item.name}`}
                title={item.description}
              >
                <InputLabel>{item.name}</InputLabel>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              {!!item.modes[MODE_ID_AUDIO] && (
                <Checkbox
                  disabled={isCheckboxDisable(MODE_ID_AUDIO)}
                  onChange={handleCheckboxChange(MODE_ID_AUDIO)}
                  checked={mapPermissionOption(
                    item.modes[MODE_ID_AUDIO],
                    MODE_ID_AUDIO,
                    permissionOptionsSelected,
                  )}
                  name={`${item.modes[MODE_ID_AUDIO]}`}
                />
              )}
            </TableCell>
            <TableCell align="center">
              {!!item.modes[MODE_ID_TEXT] && (
                <Checkbox
                  disabled={isCheckboxDisable(MODE_ID_TEXT)}
                  onChange={handleCheckboxChange(MODE_ID_TEXT)}
                  checked={mapPermissionOption(
                    item.modes[MODE_ID_TEXT],
                    MODE_ID_TEXT,
                    permissionOptionsSelected,
                  )}
                  name={`${item.modes[MODE_ID_TEXT]}`}
                />
              )}
            </TableCell>
            <TableCell align="center">
              {!!item.modes[MODE_ID_VIDEO] && (
                <Checkbox
                  disabled={isCheckboxDisable(MODE_ID_VIDEO)}
                  onChange={handleCheckboxChange(MODE_ID_VIDEO)}
                  checked={mapPermissionOption(
                    item.modes[MODE_ID_VIDEO],
                    MODE_ID_VIDEO,
                    permissionOptionsSelected,
                  )}
                  name={`${item.modes[MODE_ID_VIDEO]}`}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PermissionTable;
