import { useQuery } from "@tanstack/react-query";
import usePermissionService from "../services/PermissionService";
import { PermissionPattern } from "../views/Permission/PermissionInterfaces";

const options = {
  enabled: false,
  retry: false,
  retryOnMount: false,
};

export const useGetPermissionsPatternList = () => {
  const { getPermissionsPatternList } = usePermissionService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    PermissionPattern[],
    Error
  >(
    {
      queryKey: ["query-get-permissions-pattern-list"],
      queryFn: () => getPermissionsPatternList(),
      ...options
    }
  );

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
