import { theme as customTheme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";
import { pxToRem, theme } from "../../utils/CustomTheme";

export const styles = {
  backdrop: {
    color: customTheme.palette.common.white,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  container: (baseTheme: Theme) => ({
    backgroundColor: baseTheme.palette.background.default,
  }),
  selected: {
    border: "2px solid " + customTheme.palette.primary.main,
  },
  pointer: {
    cursor: "pointer",
  },
  sectionTitle: {
    fontWeight: "normal",
    fontSize: pxToRem(30),
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  rightBorder: {
    borderRight: "1px solid " + customTheme.palette.grey["100"],
  },
};
