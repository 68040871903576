import { useGetClient } from "../utils/HttpCommon";
import { Bible } from "../views/Bibles/BiblesInterfaces";

export interface BibleSaveValues {
  id: string;
  status: number;
}

export interface BibleGetResponse {
  data: Bible;
}

export interface BibleDeleteResponse {
  data: string;
}

export interface BibleId {
  id: string;
}

export interface BibleSaveResponse {
  data: BibleId;
}

const useBibleService = () => {
  const { http } = useGetClient();

  const getBible = async (bibleId: string): Promise<Bible> => {
    const response = await http.get<BibleGetResponse>(`/bible/${bibleId}`);
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const deleteBible = async (bibleId: string): Promise<string> => {
    const response = await http.delete<BibleDeleteResponse>(
      `/bible/${bibleId}`,
    );
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  const createBible = async (
    languageId: number,
    bible: Bible,
  ): Promise<BibleSaveValues> => {
    const bibleData = { ...bible, languageId };
    const response = await http.post<BibleSaveResponse>(`/bible`, bibleData);
    const { data, status } = response;
    const { data: responseData } = data;
    const { id } = responseData;
    return { id, status };
  };

  const updateBible = async (bible: Bible): Promise<BibleSaveValues> => {
    const response = await http.patch<BibleSaveResponse>(`/bible`, bible);
    const { data, status } = response;
    const { data: responseData } = data;
    const { id } = responseData;
    return { id, status };
  };

  return {
    getBible,
    deleteBible,
    createBible,
    updateBible,
  };
};

export default useBibleService;
