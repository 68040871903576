import { styles } from "./styles";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { AxiosError } from 'axios';
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Tooltip,
  TextField,
  MenuItem,
  IconButton,
  Stack,
  Checkbox,
} from "@mui/material";
import { Check, Clear, Info } from "@mui/icons-material";
import { LanguageDetails, LanguageProps, ExtraInfoItem } from "./LanguageInterfaces";
import { useGetLanguageDetails } from "../../hooks/useGetLanguageDetails";
import Bibles from "../Bibles/Bibles";
import Media from "../Media/Media";
import useLanguageService from "../../services/LanguageService";
import { useUserInfo } from "../../provider/UserProvider";
import AddLanguageDialog from "./AddLanguageDialog";
import { useGetCountryList } from "../../hooks/useGetCountryList";
import PickerWithSuggestion from "../../Components/PickerWithSuggestion";
import { StockNumbers } from "../StockNumbers/StockNumbers";

const Language = ({
  selectedLanguage,
  setAlertList,
  enableAutoScrolling,
}: LanguageProps) => {
  const { updateLanguage } = useLanguageService();
  const [languageDetails, setLanguageDetails] = useState<LanguageDetails>();
  const [selectedBible, setSelectedBible] = useState<string>();
  const [isSavingLanguage, setIsSavingLanguage] = useState<boolean>(false);
  const [showAddLanguageDialog, setShowAddLanguageDialog] =
    useState<boolean>(false);
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [editingLanguage, setEditingLanguage] = useState<boolean>(false);
  const { canAdminLanguages, canRestrictedReadOnly } = useUserInfo();
  const mediaSectionRef = useRef<HTMLDivElement>(null);
  const bibleSectionRef = useRef<HTMLDivElement>(null);
  const stockNumberSectionRef = useRef<HTMLDivElement>(null);

  const SENSITIVITY_VALUES = useMemo(
    () => ({
      low: "Low",
      moderate: "Moderate",
      high: "High",
      extreme: "Extreme",
    }),
    [],
  );

  const { data: countryList, refetch: getCountryList } = useGetCountryList();

  const {
    isFetching: isLanguageFetching,
    data: languageDetailsData,
    refetch: getLanguageDetails,
  } = useGetLanguageDetails({ selectedLanguage });

  const handleGetLanguageDetails = useCallback(getLanguageDetails, [
    getLanguageDetails,
  ]);

  useEffect(() => {
    getCountryList();
  }, [getCountryList]);

  useEffect(() => {
    if (selectedLanguage) {
      setEditingLanguage(false);
      getLanguageDetails();

      scrollToBibles(enableAutoScrolling);
    } else {
      setLanguageDetails(undefined);
    }

    setSelectedBible("");
  }, [selectedLanguage, getLanguageDetails, enableAutoScrolling]);

  useEffect(() => {
    if (languageDetailsData) {
      // default to low sensitivity
      if (!languageDetailsData.fcbh?.sensitivity) {
        languageDetailsData.fcbh.sensitivity = SENSITIVITY_VALUES.low;
      }
      setLanguageDetails(languageDetailsData);

      if (languageDetailsData?.bibles?.length === 1) {
        setSelectedBible(languageDetailsData.bibles[0].id);
      }
    } else {
      setSelectedBible("");
    }
  }, [
    languageDetailsData,
    setLanguageDetails,
    setSelectedBible,
    SENSITIVITY_VALUES,
  ]);

  const handleSetSelectedBible = useCallback(setSelectedBible, [
    setSelectedBible,
  ]);

  const getExtraInfo = (extraInfo: ExtraInfoItem[]): string => {
    const infoList: string[] = [];
    extraInfo.forEach((info: ExtraInfoItem) => {
      const key = Object.keys(info)[0];
      infoList.push(`${key}: ${info[key]}`);
    });
    return infoList.join("\n");
  };

  const fchbSensitivity = languageDetails?.fcbh.sensitivity;
  const handleShowSensitiveFields = useCallback((): boolean => {
    return canAdminLanguages || canRestrictedReadOnly || fchbSensitivity !== SENSITIVITY_VALUES.extreme;
  }, [canAdminLanguages, canRestrictedReadOnly, fchbSensitivity, SENSITIVITY_VALUES]);

  const addLanguage = () => {
    setShowAddLanguageDialog(true);
  };

  const handleAddLanguageDialogClose = () => {
    setShowAddLanguageDialog(false);
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEditingLanguage(true);
      setLanguageDetails((prevState) => {
        if (!prevState) {
          return undefined;
        }
        const language = { ...prevState };

        if (
          event.target instanceof HTMLInputElement &&
          event.target.type === "checkbox"
        ) {
          language.fcbh[event.target.name] = event.target.checked;
        } else {
          // For other input and textarea elements, use the value property
          language.fcbh[event.target.name] = event.target.value;
        }

        return language;
      });
    },
    [],
  );

  const saveEdits = () => {
    if (languageDetails) {
      if (!languageDetails.fcbh.fcbhName) {
        showMessage("FCBH Name is required.");
        return;
      }

      setIsSavingLanguage(true);
      updateLanguage(languageDetails.fcbh)
        .then(() => {
          showMessage("Language saved.", "success");
          setEditingLanguage(false);
          setIsSavingLanguage(false);
          getLanguageDetails();
        })
        .catch((error: unknown) => {
          let errorMessage = "There was an error saving.";

          if (error instanceof AxiosError && error.response) {
            errorMessage = error.response.data?.error || errorMessage;
          } else if (error instanceof Error) {
            errorMessage = error.message;
          }

          setIsSavingLanguage(false);
          showMessage(errorMessage);
          console.log(error);
        });
    }
  };

  const cancelEdits = () => {
    getLanguageDetails();
    setEditingLanguage(false);
  };

  const scrollToBibles = (autoScrolling: boolean) => {
    if (autoScrolling) {
      bibleSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollToMedia = useCallback(() => {
    if (enableAutoScrolling) {
      mediaSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [enableAutoScrolling]);

  const showMessage = useCallback(
    (message: string, type = "error") => {
      setAlertList((prev) => {
        if (!prev) {
          return [];
        }

        return [
          ...prev,
          {
            message: message,
            key: new Date().getTime(),
            type: type as "error" | "success" | "warning",
          },
        ];
      });
    },
    [setAlertList],
  );

  const handleShowArchived = useCallback(
    (value: boolean): void => setShowArchived(value),
    [setShowArchived],
  );

  const languageCellStyles = languageDetails?.fcbh.deletedAt
    ? [styles.rightBorder, styles.lightReadBackground, styles.alignTop]
    : [styles.rightBorder, styles.alignTop];

  const isoRolvCellStyles = languageDetails?.fcbh.deletedAt
    ? [
        styles.rightBorder,
        styles.noWordBreak,
        styles.lightReadBackground,
        styles.alignTop,
      ]
    : [
        styles.rightBorder,
        styles.noWordBreak,
        styles.greyedBackground,
        styles.alignTop,
      ];

  const tooltipISOLanguageDeleted =
    languageDetails?.fcbh?.deletedAt && languageDetails?.fcbh?.id
      ? `The ISO assigned to this row (Language ID: ${languageDetails?.fcbh?.id}) is no longer valid`
      : languageDetails?.fcbh?.id;

  const tooltipRolvLanguageDeleted =
    languageDetails?.fcbh?.deletedAt && languageDetails?.fcbh?.id
      ? `The ROLV assigned to this row (Language ID: ${languageDetails?.fcbh?.id}) is no longer valid`
      : languageDetails?.fcbh?.id;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item xs={10}>
          <Typography variant="h3" sx={styles.sectionTitle}>
            Language
          </Typography>
        </Grid>
        <Grid item xs={2} sx={styles.alignRight}>
          {canAdminLanguages ? (
            <Button color="secondary" variant="outlined" onClick={addLanguage}>
              Add Language
            </Button>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={2} sx={styles.alignRight}>
          {/* <Button color="secondary">Add Language</Button> */}
        </Grid>
      </Grid>
      <Backdrop
        sx={styles.backdrop}
        open={isLanguageFetching || isSavingLanguage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} sx={{ marginBottom: "40px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, maxWidth: "100%" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: "30px" }}>ISO</TableCell>
                <TableCell sx={{ minWidth: "50px" }}>ROLV</TableCell>
                <TableCell>
                  Heart Name
                  <Tooltip title="Heart Name displays in bible.is">
                    <Info
                      sx={[{ fontSize: 15 }, styles.pointer, styles.greyedIcon]}
                    ></Info>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  FCBH Name
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{ display: "inline" }}
                  >
                    *&nbsp;
                    <Tooltip title="FCBH Name displays in bible.is">
                      <Info
                        sx={[
                          { fontSize: 15 },
                          styles.pointer,
                          styles.greyedIcon,
                        ]}
                      ></Info>
                    </Tooltip>
                  </Typography>
                </TableCell>
                <TableCell>Alternate Names</TableCell>
                {handleShowSensitiveFields() && <TableCell>Country</TableCell>}
                <TableCell sx={{ minWidth: "80px" }}>Pseudonym</TableCell>
                <TableCell>Sensitivity</TableCell>
                {handleShowSensitiveFields() && (
                  <TableCell>Official Name</TableCell>
                )}
                <TableCell>{/* controls */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={languageDetails?.fcbh.id}>
                <TableCell sx={isoRolvCellStyles}>
                  <Tooltip title={tooltipISOLanguageDeleted}>
                    <span>{languageDetails?.fcbh.iso}</span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={isoRolvCellStyles}>
                  <Tooltip title={tooltipRolvLanguageDeleted}>
                    <span>{languageDetails?.fcbh.rolv}</span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={languageCellStyles}>
                  <TextField
                    value={languageDetails?.fcbh.heartName ?? ""}
                    onChange={(event) => handleInputChange(event)}
                    name="heartName"
                    size="small"
                    maxRows={4}
                    fullWidth
                    disabled={!selectedLanguage || !canAdminLanguages}
                  />
                </TableCell>
                <TableCell sx={languageCellStyles}>
                  <TextField
                    value={languageDetails?.fcbh.fcbhName ?? ""}
                    onChange={(event) => handleInputChange(event)}
                    name="fcbhName"
                    size="small"
                    maxRows={4}
                    fullWidth
                    disabled={!selectedLanguage || !canAdminLanguages}
                  />
                </TableCell>
                <TableCell sx={languageCellStyles}>
                  <TextField
                    value={languageDetails?.fcbh.altName ?? ""}
                    onChange={(event) => handleInputChange(event)}
                    name="altName"
                    size="small"
                    maxRows={4}
                    fullWidth
                    disabled={!selectedLanguage || !canAdminLanguages}
                  />
                </TableCell>
                {handleShowSensitiveFields() && (
                  <TableCell sx={languageCellStyles}>
                    <PickerWithSuggestion
                      type={"language.country"}
                      fieldValue={languageDetails?.fcbh.countries}
                      fieldValueSetter={setLanguageDetails}
                      options={countryList}
                      autopopulateValues={[
                        languageDetails?.iso.countries,
                        languageDetails?.rolv.countries,
                      ]}
                      editingIndicator={setEditingLanguage}
                      disabled={!selectedLanguage || !canAdminLanguages}
                    />
                  </TableCell>
                )}
                <TableCell align="center" sx={languageCellStyles}>
                  <Checkbox
                    disabled={!selectedLanguage || !canAdminLanguages}
                    onChange={(event) => handleInputChange(event)}
                    checked={!!languageDetails?.fcbh.pseudonym}
                    name="pseudonym"
                  />
                </TableCell>
                <TableCell sx={languageCellStyles}>
                  <TextField
                    select
                    value={languageDetails?.fcbh.sensitivity ?? ""}
                    onChange={(event) => handleInputChange(event)}
                    disabled={!selectedLanguage || !canAdminLanguages}
                    sx={{ minWidth: "120px" }}
                    name="sensitivity"
                    size="small"
                  >
                    <MenuItem value={SENSITIVITY_VALUES.low}>
                      {SENSITIVITY_VALUES.low}
                    </MenuItem>
                    <MenuItem value={SENSITIVITY_VALUES.moderate}>
                      {SENSITIVITY_VALUES.moderate}
                    </MenuItem>
                    <MenuItem value={SENSITIVITY_VALUES.high}>
                      {SENSITIVITY_VALUES.high}
                    </MenuItem>
                    <MenuItem value={SENSITIVITY_VALUES.extreme}>
                      {SENSITIVITY_VALUES.extreme}
                    </MenuItem>
                  </TextField>
                </TableCell>
                {handleShowSensitiveFields() && (
                  <TableCell
                    sx={[
                      styles.rightBorder,
                      styles.noWordBreak,
                      styles.alignTop,
                      languageDetails?.fcbh.deletedAt
                        ? styles.lightReadBackground
                        : null,
                    ]}
                  >
                    <TextField
                      value={languageDetails?.fcbh.officialName ?? ""}
                      onChange={(event) => handleInputChange(event)}
                      name="officialName"
                      size="small"
                      maxRows={4}
                      fullWidth
                      disabled={true}
                    />
                  </TableCell>
                )}
                <TableCell
                  sx={[
                    styles.alignCenter,
                    styles.alignTop,
                    styles.controlCellWidth,
                    languageDetails?.fcbh.deletedAt
                      ? styles.lightReadBackground
                      : null,
                  ]}
                >
                  {editingLanguage && (
                    <Stack direction="row" spacing={0}>
                      <Tooltip title="Save Changes">
                        <IconButton onClick={() => saveEdits()}>
                          <Check color="success" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel Changes">
                        <IconButton onClick={cancelEdits}>
                          <Clear color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, maxWidth: "100%" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>{/* identifier */}</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Alternate Names</TableCell>
                <TableCell>Countries</TableCell>
                <TableCell>Macrolanguage ID</TableCell>
                <TableCell>Macrolanguage Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={[styles.rightBorder, styles.noWordBreak]}>
                  ISO
                  {languageDetails?.iso.extraInfo &&
                    !!languageDetails?.iso.extraInfo.length && (
                      <Tooltip
                        title={
                          <div style={{ whiteSpace: "pre-line" }}>
                            {getExtraInfo(languageDetails?.iso.extraInfo)}
                          </div>
                        }
                      >
                        <Info
                          sx={[
                            { fontSize: 15 },
                            styles.pointer,
                            styles.greyedIcon,
                          ]}
                        ></Info>
                      </Tooltip>
                    )}
                </TableCell>
                <TableCell
                  sx={[
                    styles.rightBorder,
                    styles.noWordBreak,
                    styles.greyedBackground,
                  ]}
                >
                  {languageDetails?.iso?.id ? (
                    <a
                      href={`${process.env.REACT_APP_SIL_ORG_URL}/${languageDetails.iso.id}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {languageDetails.iso.id}
                    </a>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell
                  sx={[
                    styles.rightBorder,
                    styles.greyedBackground,
                    styles.noWordBreak,
                  ]}
                >
                  {languageDetails?.iso.name}
                </TableCell>
                <TableCell sx={[styles.rightBorder, styles.greyedBackground]}>
                  {languageDetails?.iso.altName}
                </TableCell>
                <TableCell sx={[styles.rightBorder, styles.greyedBackground]}>
                  {languageDetails?.iso.countries}
                </TableCell>
                <TableCell
                  sx={[
                    styles.rightBorder,
                    styles.greyedBackground,
                    styles.noWordBreak,
                  ]}
                >
                  {languageDetails?.iso.macrolanguageId}
                </TableCell>
                <TableCell
                  sx={[
                    styles.rightBorder,
                    styles.greyedBackground,
                    styles.noWordBreak,
                  ]}
                >
                  {languageDetails?.iso.macrolanguageName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={[styles.rightBorder, styles.noWordBreak]}>
                  ROLV
                  {languageDetails?.rolv.extraInfo &&
                    !!languageDetails?.rolv.extraInfo.length && (
                      <Tooltip
                        title={
                          <div style={{ whiteSpace: "pre-line" }}>
                            {getExtraInfo(languageDetails?.rolv.extraInfo)}
                          </div>
                        }
                      >
                        <Info
                          sx={[
                            { fontSize: 15 },
                            styles.pointer,
                            styles.greyedIcon,
                          ]}
                        ></Info>
                      </Tooltip>
                    )}
                </TableCell>
                <TableCell
                  sx={[
                    styles.rightBorder,
                    styles.noWordBreak,
                    styles.greyedBackground,
                  ]}
                >
                  {languageDetails?.rolv?.id ? (
                    <a
                      href={`${process.env.REACT_APP_GLOBAL_RECORDINGS_URL}/${languageDetails.rolv.id}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {languageDetails.rolv.id}
                    </a>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell
                  sx={[
                    styles.rightBorder,
                    styles.greyedBackground,
                    styles.noWordBreak,
                  ]}
                >
                  {languageDetails?.rolv.name}
                </TableCell>
                <TableCell sx={[styles.rightBorder, styles.greyedBackground]}>
                  {languageDetails?.rolv.altName}
                </TableCell>
                <TableCell sx={[styles.rightBorder, styles.greyedBackground]}>
                  {languageDetails?.rolv.countries}
                </TableCell>
                <TableCell sx={[styles.rightBorder, styles.greyedBackground]}>
                  {/* microlanguage id */}
                </TableCell>
                <TableCell sx={[styles.rightBorder, styles.greyedBackground]}>
                  {/* microlanguage name */}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <AddLanguageDialog
        open={showAddLanguageDialog}
        onClose={handleAddLanguageDialogClose}
      />
      <Grid item xs={12}>
        <div ref={bibleSectionRef}></div>
        <Bibles
          languageId={languageDetails?.fcbh?.id ?? 0}
          languageIso={languageDetails?.fcbh?.iso}
          bibles={languageDetails?.bibles ?? []}
          getLanguageDetails={handleGetLanguageDetails}
          selectedBible={selectedBible}
          setSelectedBible={handleSetSelectedBible}
          showMessage={showMessage}
        />
      </Grid>
      <Grid item xs={12}>
        <div ref={stockNumberSectionRef}></div>
        <StockNumbers
          bibleId={selectedBible ?? ""}
          showMessage={showMessage}
          showArchived={showArchived}
          handleShowArchived={handleShowArchived}
        />
      </Grid>
      <Grid item xs={12}>
        <div ref={mediaSectionRef}></div>
        <Media
          enableAutoScrolling={enableAutoScrolling}
          selectedLanguageId={selectedLanguage?.languageId}
          selectedBible={selectedBible}
          setAlertList={setAlertList}
          showSensitiveFields={handleShowSensitiveFields}
          scrollToMedia={handleScrollToMedia}
          showArchived={showArchived}
        />
      </Grid>
    </>
  );
};

export default Language;
