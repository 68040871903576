import { theme as customTheme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";
import { pxToRem, theme } from "../../utils/CustomTheme";

export const styles = {
  backdrop: {
    color: customTheme.palette.common.white,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  container: (baseTheme: Theme) => ({
    backgroundColor: baseTheme.palette.background.default,
  }),
  pointer: {
    cursor: "pointer",
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignTop: {
    verticalAlign: "top",
  },
  sectionTitle: {
    fontWeight: "normal",
    fontSize: pxToRem(30),
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  greyedBackground: {
    backgroundColor: customTheme.palette.grey.A400,
  },
  lightReadBackground: {
    backgroundColor: customTheme.palette.primary.light,
  },
  greyedIcon: {
    color: "#969b9e",
  },
  rightBorder: {
    borderRight: "1px solid " + customTheme.palette.grey["100"],
  },
  noWordBreak: {
    wordBreak: "unset",
  },
  controlCellWidth: {
    width: "75px",
  },
};
