import { theme as customTheme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";
import { theme } from "../../utils/CustomTheme";

export const styles = {
  backdrop: {
    color: customTheme.palette.common.white,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  subSectionTitle: {
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  pointer: {
    cursor: "pointer",
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  rightBorder: {
    borderRight: "1px solid " + customTheme.palette.grey["100"],
  },
  selected: {
    border: "2px solid " + customTheme.palette.primary.main,
  },
  greyedBackground: {
    backgroundColor: customTheme.palette.grey.A400,
  },
  controlCellWidth: {
    width: "75px",
  },
};
