import React, { useCallback } from "react";
import { styles } from "./styles";
import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { Check, Clear, Delete } from "@mui/icons-material";
import { BibleProps } from "./BiblesInterfaces";

const BibleComponent = ({
  bibleId,
  name,
  localizedName,
  translator,
  translationYear,
  index,
  isSelected,
  isNew,
  setSelectedBible,
  handleInputChange,
  handleIdBlur,
  canAdminBibles,
  isEditingBible,
  saveEdits,
  cancelEdits,
  deleteRow,
}: BibleProps) => {
  const handleSetSelectedBible = useCallback(() => {
    setSelectedBible(isNew ? "" : bibleId);
  }, [bibleId, setSelectedBible, isNew]);

  return (
    <TableRow
      onClick={handleSetSelectedBible}
      sx={[
        isSelected ? styles.selected : null,
        styles.pointer,
        styles.alignTop,
      ]}
    >
      <TableCell sx={styles.rightBorder}>
        {isNew ? (
          <TextField
            value={bibleId || ""}
            onChange={(event) => handleInputChange(event, index)}
            onBlur={() => handleIdBlur(bibleId, index)}
            name="bibleId"
            size="small"
            fullWidth
            required
            disabled={!canAdminBibles}
          />
        ) : (
          bibleId
        )}
      </TableCell>
      <TableCell sx={styles.rightBorder}>
        <TextField
          value={name || ""}
          onChange={(event) => handleInputChange(event, index)}
          name="name"
          size="small"
          fullWidth
          maxRows={4}
          required
          disabled={!canAdminBibles}
        />
      </TableCell>
      <TableCell sx={styles.rightBorder}>
        <TextField
          value={localizedName || ""}
          onChange={(event) => handleInputChange(event, index)}
          name="localizedName"
          size="small"
          fullWidth
          maxRows={4}
          disabled={!canAdminBibles}
        />
      </TableCell>
      <TableCell sx={styles.rightBorder}>
        <TextField
          value={translator || ""}
          onChange={(event) => handleInputChange(event, index)}
          name="translator"
          size="small"
          fullWidth
          disabled={!canAdminBibles}
        />
      </TableCell>
      <TableCell sx={styles.rightBorder}>
        <TextField
          value={translationYear || ""}
          onChange={(event) => handleInputChange(event, index)}
          name="translationYear"
          size="small"
          fullWidth
          disabled={!canAdminBibles}
        />
      </TableCell>
      {canAdminBibles && (
        <TableCell sx={[styles.alignCenter, styles.controlCellWidth]}>
          <Stack direction="row" spacing={0}>
            {isEditingBible && (
              <>
                <Tooltip title="Save Changes">
                  <IconButton onClick={(event) => saveEdits(event, index)}>
                    <Check color="success" fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel Changes">
                  <IconButton onClick={(event) => cancelEdits(event)}>
                    <Clear color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!isEditingBible && (
              <Tooltip title="Delete Row">
                <IconButton onClick={deleteRow(bibleId)}>
                  <Delete color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
};

export default React.memo(BibleComponent);
