import { theme as customTheme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";
import { pxToRem, theme } from "../../utils/CustomTheme";

export const styles = {
  backdrop: {
    color: customTheme.palette.common.white,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  sectionTitle: {
    fontWeight: "normal",
    fontSize: pxToRem(30),
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  subSectionTitle: {
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  patternTitle: {
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  patternSubTitle: {
    lineHeight: "100%",
    textAlign: "left",
    marginBottom: theme.spacing(3),
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  centerVertical: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  rightBorder: {
    borderRight: "1px solid " + customTheme.palette.grey["100"],
  },
  licensorTable: {
    marginBottom: theme.spacing(5),
  },
  controlCellWidth: {
    width: "75px",
  },
};
