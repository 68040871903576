import { ChangeEvent } from "react";
import { Permission as PermissionInterface } from "./PermissionInterfaces";
import { addCopyrightCombined } from "./PermissionUtils";

export const handleCombineCopyright =
  (
    setIsCombineCopyrightEnable: (value: boolean) => void,
    setEditingCopyright: (value: boolean) => void,
  ) =>
  (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === "checkbox"
    ) {
      setIsCombineCopyrightEnable(event.target.checked);
      setEditingCopyright(true);
    }
  };

export const handleCopyrightCombineCheckboxChange =
  (
    setPermission: React.Dispatch<
      React.SetStateAction<PermissionInterface | undefined>
    >,
    mediaModeType: number,
  ) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;

    setPermission((prevState) => {
      if (!prevState) {
        return undefined;
      }

      const permissionCopy = { ...prevState };
      permissionCopy.copyright = addCopyrightCombined(
        mediaModeType,
        permissionCopy.copyright,
        value,
      );
      return permissionCopy;
    });
  };
