import { useGetClient } from "../utils/HttpCommon";
import { SearchResult } from "../views/Search/SearchInterfaces";

export interface SearchResponse {
  data: SearchResult[];
}

export interface SearchRequestParameters {
  query: string;
}

const useSearchService = () => {
  const { http } = useGetClient();

  const get = async (
    params: SearchRequestParameters,
  ): Promise<SearchResult[]> => {
    const response = await http.get<SearchResponse>(`/search`, {
      params,
    });
    const { data } = response;
    const { data: responseData } = data;
    return responseData;
  };

  return {
    get,
  };
};

export default useSearchService;
