import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { AddLanguageDialogProps } from "./LanguageInterfaces";
import { theme } from "../../utils/CustomTheme";

const AddLanguageDialog = ({
  onClose,
  open,
}: AddLanguageDialogProps) => {

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle>
        Add Language
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
        Adding a new Language is expected to be extremely rare. All languages and language varieties known to ISO 639 and ROLV can be found by searching.
        </Typography>
        <Typography variant="body2" sx={{ marginTop: "10px" }}>
        Please coordinate with the Language Survey Team and the Language Curator, and request that the Language Curator add the language via email: <b>language_curator@fcbhmail.org</b>
        </Typography>        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="success">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLanguageDialog;
