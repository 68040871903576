import { useQuery } from "@tanstack/react-query";
import { Country } from "../views/Language/LanguageInterfaces";
import useLanguageService from "../services/LanguageService";

const options = {
  enabled: false,
  retry: false,
  retryOnMount: false,
};

export const useGetCountryList = () => {
  const { getCountryList } = useLanguageService();
  const { isFetching, isError, data, error, refetch } = useQuery<
    Country[],
    Error
  >({
    queryKey: ["query-get-country-list"],
    queryFn: () => getCountryList(),
    ...options
  });

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
