import { theme as customTheme } from "../../utils/CustomTheme";
import { theme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";

export const styles = {
  backdrop: {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    color: customTheme.palette.common.white,
  },
  subSectionTitle: {
    lineHeight: "100%",
    textTransform: "uppercase",
    textAlign: "left",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  alignRight: {
    textAlign: "right",
  },
  greyedBackground: {
    backgroundColor: customTheme.palette.grey.A400,
  },
  greenBackground: {
    backgroundColor: "#b9f6ca",
  },
  centerCellContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
