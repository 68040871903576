import React, { createContext, useContext } from "react";
import {
  UserContextType,
  useUserInfo as useUserInfoHook,
} from "../hooks/useUserInfo";

// Define a context for user information
const UserContext = createContext<UserContextType | null>(null);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // Get the user claims from useUserInfo hook
  const userInfoClaims = useUserInfoHook();

  return (
    <UserContext.Provider value={userInfoClaims}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to use in components
export const useUserInfo = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserInfo must be used within a UserProvider");
  }
  return context;
};
