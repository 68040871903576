import { Route, Routes, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container, Grid2 } from "@mui/material";
import { theme } from "./utils/CustomTheme";
import Home from "./views/Home/Home";
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./Components/SecureRoute";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Loading from "./Components/Loading";
import CustomErrorPage from "./Components/CustomErrorPage";  // Import the custom error page
import { useEffect } from "react";
import config from "./config";
import { UserProvider } from "./provider/UserProvider";

// Initialize OktaAuth instance with proper type
const oktaAuth = new OktaAuth(config.oidc);
type RestoreOriginalUriType = (oktaAuth: OktaAuth, originalUri: string) => void;

function App() {
  const navigate = useNavigate();

  // Define the restoreOriginalUri function with correct typing
  const restoreOriginalUri: RestoreOriginalUriType = async (
    _oktaAuth,
    originalUri,
  ) => {
    const relativeUri = toRelativeUrl(
      originalUri || "/",
      window.location.origin,
    );
    navigate(relativeUri);
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
  }, []); // Ensure the effect runs only once after mounting

  return (
    <ThemeProvider theme={theme}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <UserProvider>
          <Container
            className="App"
            maxWidth={false}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Grid2 container>
              <Grid2 size={12}>
                <Routes>
                  <Route
                    path="login/callback"
                    element={<LoginCallback loadingElement={<Loading />} errorComponent={CustomErrorPage}/>}
                  />
                  {process.env.REACT_APP_ENV === "local" ? (
                    <Route path="/" element={<Home />} />
                  ) : (
                    <Route path="/" element={<RequiredAuth />}>
                      <Route path="/" element={<Home />} />
                    </Route>
                  )}
                </Routes>
              </Grid2>
            </Grid2>
          </Container>
        </UserProvider>
      </Security>
    </ThemeProvider>
  );
}

export default App;
