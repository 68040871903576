import { useState, useMemo, useCallback } from "react";
import { useQueries } from "@tanstack/react-query";
import usePermissionService from "../services/PermissionService";
import {
  LicenseGroup,
  Permission,
} from "../views/Permission/PermissionInterfaces";

interface UseLicenseGroupCopyrightsResult {
  data: Record<string, string>;
  isLoading: boolean;
  isError: boolean;
  error?: Error;
  refetchAll: () => void;
  refetchById: (licenseId: number) => void;
}

const removeFirstAndConcatenate = (str: string) => {
  // e.g "BiblbeID-text"
  const parts = str.split("-");

  if (parts.length > 1) {
    parts.shift();
    // Concatenate the remaining elements back into a string e.g. audio
    return parts.join("-");
  }

  // If there's only one element, return the original string
  return str;
};

export const useLicenseGroupCopyrights = (
  licenseGroups: LicenseGroup[],
  permission?: Permission,
): UseLicenseGroupCopyrightsResult => {
  const { getAgreement } = usePermissionService();
  const [error, setError] = useState<Error>();

  const options = {
    // the time in milliseconds after data is considered stale
    staleTime: 5 * 60 * 1000,
  };

  const queries: {
    staleTime: number;
    queryKey: (string | number)[];
    queryFn: () => Promise<Permission>;
  }[] = [];

  licenseGroups.forEach((licenseGroup) => {
    // avoid making a request if the permission has already been fetched
    if (licenseGroup.id !== permission?.id) {
      queries.push({
        queryKey: ["query-get-permission", licenseGroup.id],
        queryFn: () => getAgreement(licenseGroup.id),
        ...options,
      });
    }
  });

  const results = useQueries({
    queries,
  });

  const refetchAll = useCallback(() => {
    results.forEach((result) => result.refetch());
  }, [results]);

  const refetchById = useCallback(
    (licenseId: number) => {
      results.forEach((result) => {
        if (result.data?.id === licenseId) {
          result.refetch();
        }
      });
    },
    [results],
  );

  const {
    data: copyrights,
    isLoading,
    isError,
  } = useMemo(() => {
    let loading = false;
    let hasError = false;
    const newCopyrights: Record<string, string> = {};

    const key = removeFirstAndConcatenate(permission?.name ?? "");
    newCopyrights[key] = permission?.copyright ?? "";

    results.forEach((result, index) => {
      if (result.isLoading) {
        loading = true;
      }
      if (result.isError) {
        hasError = true;
        setError(result.error);
      }
      if (result.isSuccess && licenseGroups[index]) {
        const key = removeFirstAndConcatenate(result.data?.name);
        newCopyrights[key] = result.data?.copyright || "";
      }
    });

    return {
      data: newCopyrights,
      isLoading: loading,
      isError: hasError,
    };
  }, [results, permission, licenseGroups]);

  return {
    data: copyrights,
    isLoading,
    isError,
    error,
    refetchAll,
    refetchById,
  };
};
