import { Check, Clear, Search } from "@mui/icons-material";
// TODO: It will be temporarily hidden
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  TextField,
  IconButton,
  Stack,
  TablePagination,
  InputAdornment,
  Grid,
} from "@mui/material";
import { AxiosError } from 'axios';
import LoadingButton from "@mui/lab/LoadingButton";
import { theme } from "../../utils/CustomTheme";
import { Licensor, ManageLicensorsDialogProps } from "./PermissionInterfaces";
import { styles } from "./styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import usePermissionService from "../../services/PermissionService";
import { AlertMessage } from "../Home/HomeInterfaces";

const ManageLicensorsDialog = ({
  onClose,
  open,
  setAlertList,
  licensorList,
}: ManageLicensorsDialogProps) => {
  const {
    getLicensorList: getLicensorListService,
    updateLicensorWithFile,
    createLicensor: createLicensorService,
    deleteLicensor,
  } = usePermissionService();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [editingLicensor, setEditingLicensor] = useState<number>(-1);
  const [page, setPage] = useState(0);
  const [fullLicensorList, setFullLicensorList] = useState<Licensor[]>([]);
  const [filteredLicensorList, setFilteredLicensorList] = useState<Licensor[]>(
    [],
  );
  const [filterText, setFilterText] = useState<string>("");
  const [newLicensor, setNewLicensor] = useState<Licensor>({
    name: "",
    logo: "",
    id: 0,
  });
  const [logoFile, setLogoFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [showNewLicensor, setShowNewLicensor] = useState<boolean>(false);
  const [isSavingLicensor, setIsSavingLicensor] = useState<boolean>(false);

  const visibleRows = useMemo(
    () =>
      filteredLicensorList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [page, rowsPerPage, filteredLicensorList],
  );

  const applyFilter = useCallback(() => {
    if (filterText) {
      setFilteredLicensorList(
        fullLicensorList.filter((element) =>
          element.name.toLowerCase().includes(filterText.toLowerCase()),
        ),
      );
    } else {
      setFilteredLicensorList(fullLicensorList);
    }
  }, [filterText, setFilteredLicensorList, fullLicensorList]);

  useEffect(() => {
    if (editingLicensor > 0) {
      setShowNewLicensor(false);
    }
  }, [editingLicensor]);

  useEffect(() => {
    if (licensorList) {
      setFullLicensorList(licensorList);
    }
  }, [licensorList, setFullLicensorList]);

  useEffect(() => {
    if (fullLicensorList) {
      setFilteredLicensorList(fullLicensorList);
    }
  }, [fullLicensorList, setFilteredLicensorList]);

  useEffect(() => {
    applyFilter();
  }, [filterText, applyFilter]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const handleInputChange =
    (editedId: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEditingLicensor(editedId);

      setFilteredLicensorList((prevState) => {
        if (!prevState) {
          return [];
        }

        const licensorsCopy = [...prevState];
        if (licensorsCopy) {
          licensorsCopy.forEach((licensor: Licensor) => {
            if (editedId === licensor.id) {
              if (event.target && event.target.name === "name") {
                licensor.name = event.target.value;
              } else if (event.target && event.target.name === "logo") {
                licensor.logo = event.target.value;
              }
            }
          });
        }

        return licensorsCopy;
      });
    };

  const handleNewInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setNewLicensor((prevState: Licensor) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNewFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.startsWith("image/")) {
        setLogoFile(file);
        setSelectedFileName(file.name);
      } else {
        showMessage("Please upload a valid image file.");
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFileChange =
    (editedId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditingLicensor(editedId);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        if (file.type.startsWith("image/")) {
          setLogoFile(file);
          setSelectedFileName(file.name);
        } else {
          showMessage("Please upload a valid image file.");
        }
      }
    };

  const createLicensor = () => {
    setEditingLicensor(-1);
    setShowNewLicensor(true);
  };

  const cancelNew = () => {
    setNewLicensor({
      name: "",
      logo: "",
      id: 0,
    });
    setShowNewLicensor(false);
    setLogoFile(null);
    setSelectedFileName(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setEditingLicensor(-1);
    setPage(newPage);
  };

  const saveEdits = (licensorId: number) => {
    if (filteredLicensorList) {
      setIsSavingLicensor(true);
      const editedLicensor = filteredLicensorList.find(
        (element) => element.id === licensorId,
      );
      if (editedLicensor?.id) {
        if (!editedLicensor?.name) {
          showMessage("Licensor Name is Required");
          setIsSavingLicensor(false);
          return;
        }

        const formData = new FormData();
        formData.append("id", `${editedLicensor.id}`);
        formData.append("name", editedLicensor.name);

        if (editedLicensor.logo) {
          formData.append("logo", editedLicensor.logo);
        }

        if (logoFile) {
          formData.append("logo-file", logoFile);
        }

        updateLicensorWithFile(formData)
          .then(() => {
            showMessage("Licensor updated.", "success");
            setEditingLicensor(-1);
            getLicensorList();
            setIsSavingLicensor(false);
            setLogoFile(null);
            setSelectedFileName(null);
          })
          .catch((error: unknown) => {
            let errorMessage = "Error updating licensor.";

            if (error instanceof AxiosError && error.response) {
              errorMessage = error.response.data?.error || errorMessage;
            } else if (error instanceof Error) {
              errorMessage = error.message;
            }
            showMessage(errorMessage);
            console.log(error);
            setIsSavingLicensor(false);
          });
      } else {
        setIsSavingLicensor(false);
      }
    }
  };

  const saveNew = () => {
    if (newLicensor) {
      setIsSavingLicensor(true);
      if (!newLicensor?.name) {
        showMessage("Licensor Name is Required");
        setIsSavingLicensor(false);
        return;
      }

      const formData = new FormData();
      formData.append("name", newLicensor.name);

      if (newLicensor.logo) {
        formData.append("logo", newLicensor.logo);
      }

      if (logoFile) {
        formData.append("logo-file", logoFile);
      }

      createLicensorService(formData)
        .then(() => {
          showMessage("Licensor created.", "success");

          setNewLicensor({
            name: "",
            logo: "",
            id: 0,
          });
          setEditingLicensor(-1);
          setShowNewLicensor(false);

          getLicensorList();
          setIsSavingLicensor(false);
          setLogoFile(null);
          setSelectedFileName(null);
        })
        .catch((error: unknown) => {
          let errorMessage = "Error creating licensor.";

          if (error instanceof AxiosError && error.response) {
            errorMessage = error.response.data?.error || errorMessage;
          } else if (error instanceof Error) {
            errorMessage = error.message;
          }
          showMessage(errorMessage);
          console.log(error);
          setIsSavingLicensor(false);
        });
    }
  };

  const cancelEdits = () => {
    applyFilter();
    setEditingLicensor(-1);
    setLogoFile(null);
    setSelectedFileName(null);
  };

  const closeDialog = () => {
    setFilterText("");
    setNewLicensor({
      name: "",
      logo: "",
      id: 0,
    });
    setEditingLicensor(-1);
    setShowNewLicensor(false);
    setPage(0);
    onClose();
  };

  const handleConfirmDeleteLicensor = () => {
    setConfirmationDialogOpen(false);
    if (editingLicensor) {
      deleteLicensor(editingLicensor).then(() => {
        setEditingLicensor(-1);
        getLicensorList();
      });
    }
  };

  const handleCancelConfirmation = () => {
    setConfirmationDialogOpen(false);
    setEditingLicensor(-1);
  };

  const getLicensorList = () => {
    getLicensorListService().then((response: Licensor[]) => {
      setFullLicensorList([...response]);
      applyFilter();
    });
  };

  const showMessage = (message: string, type = "error") => {
    setAlertList((prev: AlertMessage[]) => {
      return [
        ...prev,
        {
          message: message,
          key: new Date().getTime(),
          type: type as "error" | "success" | "warning",
        },
      ];
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"xl"}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="baseline">
          <Grid item xs={9}>
            <Typography variant="h5">Manage Licensors</Typography>
          </Grid>
          <Grid item xs={3} sx={styles.alignRight}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={createLicensor}
              sx={{ marginRight: "10px" }}
            >
              Create Licensor
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Dialog open={confirmationDialogOpen}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this licensor?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeleteLicensor} color="primary">
              Confirm
            </Button>
            <Button
              onClick={handleCancelConfirmation}
              color="secondary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <TextField
          autoFocus
          placeholder="Filter"
          value={filterText}
          name="search"
          fullWidth
          sx={{ width: "100%", marginBottom: "20px" }}
          onChange={handleFilterChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" color="primary">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {showNewLicensor && (
          <>
            <Grid container>
              <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
                <TextField
                  label="Name"
                  required
                  value={newLicensor.name || ""}
                  onChange={handleNewInputChange}
                  name="name"
                  fullWidth
                  sx={{ width: "100%" }}
                />
                <TextField
                  label="Logo URL"
                  value={newLicensor.logo ?? ""}
                  onChange={handleNewInputChange}
                  name="logo"
                  fullWidth
                  sx={{ width: "100%" }}
                />
                {/* TODO: It will be temporarily hidden */}
                {/* <Stack direction="column" spacing={0.5} sx={{ width: "30%" }}>
                  <Button
                    component="label"
                    color="secondary"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      key="licensor-logo-file"
                      type="file"
                      accept="image/*"
                      onChange={handleNewFileChange}
                    />
                  </Button>
                  {selectedFileName && (
                    <Typography
                      noWrap
                      variant="caption"
                    >
                      {selectedFileName}
                    </Typography>
                  )}
                </Stack> */}
              </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: "20px" }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "right",
                  gap: "10px",
                }}
              >
                {isSavingLicensor ? (
                  <LoadingButton loading variant="text">
                    Submit
                  </LoadingButton>
                ) : (
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Save Changes">
                      <IconButton onClick={saveNew} aria-label="save">
                        <Check color="success" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel Changes">
                      <IconButton onClick={cancelNew} aria-label="cancel">
                        <Clear color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, maxWidth: "100%" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                  <Tooltip title="Name Required">
                    <Typography
                      color="primary"
                      variant="body1"
                      sx={{ display: "inline" }}
                    >
                      *&nbsp;
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>Logo URL</TableCell>
                {/* TODO: It will be temporarily hidden */}
                {/* <TableCell>Upload Logo</TableCell> */}
                <TableCell>{/* controls */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((licensor: Licensor, index: number) => (
                <TableRow key={index}>
                  <TableCell sx={styles.rightBorder}>
                    <TextField
                      placeholder="Name"
                      value={licensor.name || ""}
                      onChange={handleInputChange(licensor.id)}
                      name="name"
                      size="small"
                      fullWidth
                      required
                      sx={{ width: "100%" }}
                    />
                  </TableCell>
                  <TableCell sx={styles.rightBorder}>
                    <TextField
                      placeholder="Logo URL"
                      value={licensor.logo ?? ""}
                      onChange={handleInputChange(licensor.id)}
                      name="logo"
                      size="small"
                      fullWidth
                      sx={{ width: "100%" }}
                    />
                  </TableCell>
                  {/* TODO: It will be temporarily hidden */}
                  {/* <TableCell sx={styles.rightBorder} width={150}>
                    <Stack direction="column" spacing={0.5}>
                      <Button
                        component="label"
                        color="secondary"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload File
                        <VisuallyHiddenInput
                          key={`licensor-logo-file${index}`}
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange(licensor.id)}
                        />
                      </Button>
                      {editingLicensor === licensor.id && selectedFileName && (
                        <Typography
                          noWrap
                          variant="caption"
                        >
                          {selectedFileName}
                        </Typography>
                      )}
                    </Stack>
                  </TableCell> */}
                  <TableCell sx={[styles.alignCenter, styles.controlCellWidth]}>
                    {editingLicensor === licensor.id &&
                      (isSavingLicensor ? (
                        <LoadingButton loading variant="text">
                          Submit
                        </LoadingButton>
                      ) : (
                        <Stack direction="row" spacing={0}>
                          <Tooltip title="Save Changes">
                            <IconButton
                              onClick={() => saveEdits(licensor.id)}
                              aria-label="save"
                            >
                              <Check color="success" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel Changes">
                            <IconButton
                              onClick={cancelEdits}
                              aria-label="cancel"
                            >
                              <Clear color="primary" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ))}
                    {
                      //  TODO - uncomment once delete endpoint is in place
                      //  editingLicensor !== index &&
                      //   <Tooltip title="Delete Licensor">
                      //     <IconButton
                      //       onClick={() => handleDeleteLicensor(licensor.id)}
                      //     >
                      //       <Delete color="primary" fontSize="small" />
                      //     </IconButton>
                      //   </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={filteredLicensorList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageLicensorsDialog;
