import { ContentCopy } from "@mui/icons-material";
import { Autocomplete, TextField, Tooltip, Button } from "@mui/material";

export interface PickerProps {
  type: string;
  fieldValue: string | undefined;
  fieldValueSetter: any;
  index?: number;
  options: PickerObject[] | undefined;
  autopopulateValues: any[];
  editingIndicator: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

export interface PickerObject {
  name: string;
  id: string;
}

const PickerWithSuggestion = ({
  type,
  fieldValue,
  fieldValueSetter,
  index,
  options,
  autopopulateValues,
  editingIndicator,
  disabled,
}: PickerProps) => {
  const parseCountries = (countryCode: string) => {
    let pickerObject: PickerObject = {} as PickerObject;

    const match = options?.find(
      (element: PickerObject) => element.id === countryCode
    );
    if (match) {
      pickerObject = { ...match };
    }

    return pickerObject;
  };

  const handleInputChange = (value: PickerObject | null) => {
    if (type === "language.country") {
      editingIndicator(true);
      fieldValueSetter((prevState: any) => {
        const existingFieldValue = { ...prevState };
        existingFieldValue.fcbh.countries = value?.id ?? "";

        return existingFieldValue;
      });
    } else if (type === "addLanguage.country") {
      fieldValueSetter((prevState: any) => {
        const existingFieldValue = { ...prevState };
        existingFieldValue.countries = value?.id ?? "";

        return existingFieldValue;
      });
    } else if (type === "media.country") {
      editingIndicator(true);

      fieldValueSetter((prevState: any) => {
        const existingFieldValue = [...prevState];
        if (existingFieldValue) {
          existingFieldValue.forEach((media, loopIndex) => {
            if (index === loopIndex) {
              media.country = value?.id ?? "";
            }
          });
        }
        return existingFieldValue;
      });
    }
  };

  const autopopulateValue = () => {
    if (type === "language.country") {
      editingIndicator(true);
      fieldValueSetter((prevState: any) => {
        const existingFieldValue = { ...prevState };
        const autopopulateValues = getAutopopulateValue();
        const codeList: string[] = [];
        autopopulateValues?.split(",").forEach((autopopulateValue: string) => {
          const pickerObjectMatch = options?.find(
            (element: PickerObject) =>
              element.name.toLowerCase() ===
              autopopulateValue.trim().toLowerCase()
          );
          if (pickerObjectMatch) {
            codeList.push(pickerObjectMatch.id);
          }
        });

        existingFieldValue.fcbh.countries = codeList.join(";");
        return existingFieldValue;
      });
    }
  };

  const getAutopopulateValue = () => {
    if (!autopopulateValues.length) {
      return;
    } else {
      return autopopulateValues.find((element) => !!element);
    }
  };

  const autoCompleteValue = parseCountries(fieldValue ?? "");

  return (
    <>
      <Autocomplete
        autoHighlight
        onChange={(_, value) => handleInputChange(value)}
        value={autoCompleteValue?.id ? autoCompleteValue : null}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options ?? []}
        sx={{ minWidth: "200px" }}
        size="small"
        fullWidth
        renderInput={(params) => <TextField {...params} />}
        disabled={disabled}
      />
      {getAutopopulateValue() && !fieldValue && (
        <Tooltip title="Populate value from reference data.">
          <Button
            color="secondary"
            size="small"
            onClick={autopopulateValue}
            startIcon={<ContentCopy />}
          >
            {"Use '" + getAutopopulateValue() + "'"}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default PickerWithSuggestion;
