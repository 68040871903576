import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Container,
  Typography,
  Box,
  Alert,
  IconButton,
  Snackbar,
} from "@mui/material";
import Search from "../Search/Search";
import Language from "../Language/Language";
import { styles } from "./styles";
import { SearchResult } from "../Search/SearchInterfaces";
import { Close } from "@mui/icons-material";
import { AlertMessage } from "./HomeInterfaces";
import Logout from "../../Components/Logout";

const Home = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<SearchResult>();
  const [alertList, setAlertList] = useState<AlertMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<AlertMessage | undefined>(
    undefined,
  );
  const [enableAutoScrolling, setEnableAutoScrolling] =
    useState<boolean>(false);
  const handleAutoScrollingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEnableAutoScrolling(event.target.checked);
  };

  const handleSetAlertList = useCallback(setAlertList, [setAlertList]);

  useEffect(() => {
    if (alertList.length && !messageInfo) {
      setMessageInfo({ ...alertList[0] });
      setAlertList((prev) => prev.slice(1));
      setOpen(true);
    } else if (alertList.length && messageInfo && open) {
      setTimeout(() => setOpen(false), 3000);
    }
  }, [alertList, setAlertList, messageInfo, open]);

  const handleAlertClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleAlertExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Box
      sx={(theme) => ({ backgroundColor: theme.palette.background.default })}
    >
      <Container maxWidth={false} sx={styles.pageMargin}>
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          TransitionProps={{ onExited: handleAlertExited }}
        >
          <Alert
            severity={messageInfo?.type ? messageInfo.type : "error"}
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {messageInfo?.message}
          </Alert>
        </Snackbar>
        <Grid
          container
          spacing={2}
          direction={"row"}
          justifyContent={"flex-end"}
        >
          <Grid item xs={1}>
            <Logout />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1" sx={styles.homeTitle}>
              Bible Brain Language Media & Permission
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Search
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              setAlertList={setAlertList}
              enableAutoScrolling={enableAutoScrolling}
              handleAutoScrollingChange={handleAutoScrollingChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Language
              selectedLanguage={selectedLanguage}
              setAlertList={handleSetAlertList}
              enableAutoScrolling={enableAutoScrolling}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
