import { useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { SearchResult, SearchResultListProps } from "./SearchInterfaces";
import { styles } from "./styles";
import { theme } from "../../utils/CustomTheme";
import { TableSortLabel, Tooltip } from "@mui/material";

type Order = "asc" | "desc";

const SearchResultList = ({
  results,
  selectedLanguage,
  setSelectedLanguage,
}: SearchResultListProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("iso");

  function descendingComparator(a: SearchResult, b: SearchResult, orderBy: string) {
    const aValue = orderBy === "stocknumbers" ? a.stocknumbers.join(", ") : a[orderBy as keyof SearchResult];
    const bValue = orderBy === "stocknumbers" ? b.stocknumbers.join(", ") : b[orderBy as keyof SearchResult];

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  };

  const sortedRows = useMemo(
    () => {
      if (orderBy) {
        return [...results].sort((a: SearchResult, b: SearchResult) => {
          return order === "desc"
            ? descendingComparator(a, b, orderBy)
            : -descendingComparator(a, b, orderBy);
        })
      } else {
        return results;
      }
    },
    [order, orderBy, results],
  );

  const sortResults = (columnId: string) => {
    if (orderBy === columnId) {
      if (order === "asc") {
        setOrder("desc");
      } else if (order === "desc") {
        setOrder("asc");
        setOrderBy("");
      }
    } else {
      setOrderBy(columnId);
      setOrder("asc");
    }
  }

  const selectLanguage = (item: SearchResult) => {
    setSelectedLanguage(item);
  };

  if (!results.length) {
    return null;
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 375 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              key="iso"
              sortDirection={orderBy === "iso" ? order : false}
              sx={{ minWidth: "55px", backgroundColor: theme.palette.common.white }}>
              <TableSortLabel
                active={orderBy === "iso"}
                direction={orderBy === "iso" ? order : "asc"}
                onClick={() => sortResults("iso")}
              >ISO</TableSortLabel>
            </TableCell>
            <TableCell
              key="rolv"
              sortDirection={orderBy === "rolv" ? order : false}
              sx={{ minWidth: "70px", backgroundColor: theme.palette.common.white }}>
              <TableSortLabel
                active={orderBy === "rolv"}
                direction={orderBy === "rolv" ? order : "asc"}
                onClick={() => sortResults("rolv")}
              >ROLV</TableSortLabel>
            </TableCell>
            <TableCell
              key="fcbhName"
              sortDirection={orderBy === "fcbhName" ? order : false}
              sx={{ minWidth: "100px", backgroundColor: theme.palette.common.white }}>
              <TableSortLabel
                active={orderBy === "fcbhName"}
                direction={orderBy === "fcbhName" ? order : "asc"}
                onClick={() => sortResults("fcbhName")}
              >FCBH Name</TableSortLabel>
            </TableCell>
            <TableCell
              key="altName"
              sortDirection={orderBy === "altName" ? order : false}
              sx={{ minWidth: "100px", backgroundColor: theme.palette.common.white }}>
              <TableSortLabel
                active={orderBy === "altName"}
                direction={orderBy === "altName" ? order : "asc"}
                onClick={() => sortResults("altName")}
              >Alternate Names</TableSortLabel>
            </TableCell>
            <TableCell
              key="countryName"
              sortDirection={orderBy === "countryName" ? order : false}
              sx={{ minWidth: "100px", backgroundColor: theme.palette.common.white }}>
              <TableSortLabel
                active={orderBy === "countryName"}
                direction={orderBy === "countryName" ? order : "asc"}
                onClick={() => sortResults("countryName")}
              >Country</TableSortLabel>
            </TableCell>
            <TableCell
              key="stocknumbers"
              sortDirection={orderBy === "stocknumbers" ? order : false}
              sx={{ backgroundColor: theme.palette.common.white }}>
              <TableSortLabel
                active={orderBy === "stocknumbers"}
                direction={orderBy === "stocknumbers" ? order : "asc"}
                onClick={() => sortResults("stocknumbers")}
              >Stock Numbers</TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((item: SearchResult) => (
            <TableRow
              key={`${item.languageId}_${item.fcbhName}`}
              onClick={() => selectLanguage(item)}
              sx={[
                selectedLanguage?.languageId === item.languageId ||
                  results.length === 1
                  ? styles.selected
                  : null,
                styles.pointer,
              ]}
            >
              <TableCell sx={styles.rightBorder}>
                <Tooltip title={item.languageId}>
                  <span>{item.iso}</span>
                </Tooltip>
              </TableCell>
              <TableCell sx={styles.rightBorder}>
                <Tooltip title={item.languageId}>
                  <span>{item.rolv}</span>
                </Tooltip>
              </TableCell>
              <TableCell sx={styles.rightBorder}>{item.fcbhName}</TableCell>
              <TableCell sx={styles.rightBorder}>{item.altName}</TableCell>
              <TableCell sx={styles.rightBorder}>{item.countryName}</TableCell>
              <TableCell sx={styles.rightBorder}>
                {item.stocknumbers?.join(", ")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchResultList;
